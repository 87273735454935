/* eslint-disable require-yield */
import { put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import Api from '../../services/Apis/index';
import deviceSlice from '../slices/device.slice';
import loaderSlice from '../slices/loader.slice';
import locationSlice from '../slices/location.slice';

function* getAttachedDevice(action: any): any {
  const values: object = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    const response = yield Api.device.getAttachedDevice(values);
    if (!response.data.error) {
      yield put(deviceSlice.actions.setAttachedDevice(response.data.data));
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('error');
    yield put(loaderSlice.actions.hide());
  }
}
function* getUnAttachedDevice(action: any): any {
  const values: object = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    const response = yield Api.device.getUnAttachedDevice(values);
    if (!response.data.error) {
      yield put(deviceSlice.actions.setUnAttachedDevice(response.data.data));
    } else if (response.data.error) {
      if (response.data.msg === 'No doors found') {
        yield put(deviceSlice.actions.setUnAttachedDevice([]));
      }
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('error');
    yield put(loaderSlice.actions.hide());
  }
}
function* updateDoorClient(action: any): any {
  const values: any = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    const response = yield Api.device.updateDoorClient(values.data);
    if (!response.data.error) {
      const indexData = {
        limit: 10,
        pageNo: 1,
        // pageNo: values.listIndex,
      };
      yield put(deviceSlice.actions.getUnAttachedDevice(indexData));
      toast.success(response.data.msg);
      if (values.treeView[action.payload.locationIndex].children.length > 0) {
        if (
          values.treeView[action.payload.locationIndex].children[
            action.payload.buildingIndex
          ].children.length > 0
        ) {
          if (
            values.treeView[action.payload.locationIndex].children[
              action.payload.buildingIndex
            ].children[action.payload.floorIndex].children.length > 0
          ) {
            if (
              values.treeView[action.payload.locationIndex].children[
                action.payload.buildingIndex
              ].children[action.payload.floorIndex].children[
                action.payload.departmentIndex
              ].children.length > 0
            ) {
              if (
                values.locationIndex !== '' &&
                values.buildingIndex !== '' &&
                values.floorIndex !== '' &&
                values.departmentIndex !== ''
              ) {
                const data = {
                  id: values.data.department_id,
                  type: 'department',
                  index: values.locationIndex,
                  buildingIndex: values.buildingIndex,
                  floorIndex: values.floorIndex,
                  departmentIndex: values.departmentIndex,
                };
                yield put(locationSlice.actions.getDoorByDepartmentTree(data));
              }
            }
          }
        }
      }
      // yield put(deviceSlice.actions.setUnAttachedDevice(response.data.data));
    } else if (response.data.msg) {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('error');
    yield put(loaderSlice.actions.hide());
  }
}

function* getAllDevices(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.device.getAllDevices(values);
    yield put(deviceSlice.actions.setDevices(response.data.data));
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield put(loaderSlice.actions.hide());
  }
}

function* deviceSaga() {
  yield takeEvery('device/getAttachedDevice', getAttachedDevice);
  yield takeEvery('device/getUnAttachedDevice', getUnAttachedDevice);
  yield takeEvery('device/updateDoorClient', updateDoorClient);
  yield takeEvery('device/getAllDevices', getAllDevices);
}

export default deviceSaga;
