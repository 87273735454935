/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { APP_ENTRYPOINT } from '../../../config/config';
import { request } from '../../request/Request';

function* getAllDepartments(values: any) {
  return yield request.get(`${APP_ENTRYPOINT}/department/getAllDepartments`, {
    params: values,
  });
}

const department = {
  getAllDepartments,
};

export default department;
