/* eslint-disable import/no-unresolved */
import { all } from 'redux-saga/effects';
import authSaga from '../saga/auth.saga';
import locationSaga from '../saga/location.saga';
import userSaga from '../saga/users.saga';
import dashboard from '../saga/dashboard.saga';
import deviceSaga from '../saga/device.saga';
import buildingSaga from '../saga/building.saga';
import floorSaga from '../saga/floor.saga';
import departmentSaga from '../saga/department.saga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    locationSaga(),
    userSaga(),
    dashboard(),
    deviceSaga(),
    floorSaga(),
    buildingSaga(),
    departmentSaga(),
  ]);
}
