/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { APP_ENTRYPOINT } from '../../../config/config';
import { request } from '../../request/Request';

function getAllBuildings(values: any) {
  return request.get(`${APP_ENTRYPOINT}/client/getAllBuildings`, {
    params: values,
  });
}

const building = {
  getAllBuildings,
};

export default building;
