/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { APP_ENTRYPOINT } from '../../../config/config';
import { request } from '../../request/Request';

function getAllFloors(values: any) {
  return request.get(`${APP_ENTRYPOINT}/floor/getAllFloors`, {
    params: values,
  });
}

const floor = {
  getAllFloors,
};

export default floor;
