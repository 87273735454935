/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-param-reassign */
/* eslint no-unused-vars: "error" */

import { createSlice } from '@reduxjs/toolkit';

type InitialState = {
  attachedDevice: any;
  unAttachedDevice: any;
  devices: any;
};

const initialState: InitialState = {
  attachedDevice: '',
  unAttachedDevice: '',
  devices: [],
};

export const getAttachedDevice = (state: InitialState, action: any) => {};
export const getUnAttachedDevice = (state: InitialState, action: any) => {};
export const setAttachedDevice = (state: InitialState, action: any) => {
  state.attachedDevice = action.payload;
};
export const setUnAttachedDevice = (state: InitialState, action: any) => {
  state.unAttachedDevice = action.payload;
};

const getAllDevices = (state: any, action: any) => state;

const setDevices = (state: any, action: any) => {
  state.devices = action.payload;
};

export const updateDoorClient = (state: InitialState, action: any) => {};
const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    getAttachedDevice,
    getUnAttachedDevice,
    setAttachedDevice,
    setUnAttachedDevice,
    updateDoorClient,
    getAllDevices,
    setDevices,
  },
});

export default deviceSlice;
