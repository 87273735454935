/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

type InitialState = {
  floors: any;
};

const initialState: InitialState = {
  floors: [],
};

const getAllFloors = (state: InitialState, action: any) => state;

const setFloors = (state: InitialState, action: any) => {
  state.floors = action.payload;
};

const floorSlice = createSlice({
  name: 'floor',
  initialState,
  reducers: {
    getAllFloors,
    setFloors,
  },
});

export default floorSlice;
