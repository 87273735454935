/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

type InitialState = {
  buildings: any;
};

const initialState: InitialState = {
  buildings: [],
};

const getAllBuildings = (state: InitialState, action: any) => state;

const setBuildingsByLocation = (state: InitialState, action: any) => {
  state.buildings = action.payload;
};

const buildingSlice = createSlice({
  name: 'building',
  initialState,
  reducers: {
    getAllBuildings,
    setBuildingsByLocation,
  },
});

export default buildingSlice;
