/* eslint-disable import/no-unresolved */
import { combineReducers } from 'redux';
import authSlice from '../slices/auth.slice';
import loaderSlice from '../slices/loader.slice';
import locationSlice from '../slices/location.slice';
import userSlice from '../slices/users.slice';
import dashboardSlice from '../slices/dashboard.slice';
import deviceSlice from '../slices/device.slice';
import buildingSlice from '../slices/building.slice';
import departmentSlice from '../slices/department.slice';
import floorSlice from '../slices/floor.slice';

const rootReducer = combineReducers({
  loader: loaderSlice.reducer,
  auth: authSlice.reducer,
  location: locationSlice.reducer,
  user: userSlice.reducer,
  dashboard: dashboardSlice.reducer,
  device: deviceSlice.reducer,
  building: buildingSlice.reducer,
  department: departmentSlice.reducer,
  floor: floorSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
