/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

type InitialState = {
  departments: any;
};

const initialState: InitialState = {
  departments: [],
};

const getAllDepartments = (state: InitialState, action: any) => state;

const setDepartment = (state: InitialState, action: any) => {
  state.departments = action.payload;
};

const departmentSlice = createSlice({
  name: 'department',
  initialState,
  reducers: {
    getAllDepartments,
    setDepartment,
  },
});

export default departmentSlice;
