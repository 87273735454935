/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import authentication from './authentication/Authentication';
import device from './device/Device';
import building from './building/building';
import floor from './floor/floor';
import department from './department/department';
import location from './location/location';

const Api = {
  authentication,
  device,
  building,
  floor,
  department,
  location,
};

export default Api;
