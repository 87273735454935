/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { APP_ENTRYPOINT } from '../../../config/config';
import { request } from '../../request/Request';

function* getAllLocations(values: any) {
  return yield request.get(`${APP_ENTRYPOINT}/client/getAllLocations`, {
    params: values,
  });
}

const location = {
  getAllLocations,
};

export default location;
